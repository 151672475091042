import styles from "./Loading.module.scss";
import Countdown from "react-countdown";

const Loading = () => {
  return (
    <div className={styles.loading__wrapper}>
      <div className={styles.loading}>
        <div className={styles.loading__inside}></div>
      </div>

      <Countdown
        date={Date.now() + 105000}
        renderer={({ seconds, minutes }) => (
          <span>
            {minutes}:{seconds} sec
          </span>
        )}
      />
    </div>
  );
};

export default Loading;
