import styles from "../userInfo/UserInfo.module.scss";
import React from "react";
import { useGetImgHistory } from "../../api/userApi";

const PictureHistory = () => {
  const { data: picture } = useGetImgHistory();

  return (
    <div>
      {picture?.images_urls?.length > 0 && (
        <div className={styles.info__photos_row}>
          <div>
            <img
              src={
                picture?.images_urls[picture?.images_urls?.length - 1]?.picture
              }
              alt=""
            />
          </div>

          <div className={styles.info__photos_grid}>
            {picture?.images_urls?.map((item: any) => (
              <img src={item?.picture} alt="" />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default PictureHistory;
