import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import Button from "../../common/button/Button";
import Input from "../../common/input/Input";
import styles from "./CreditCardForm.module.scss";
import {
  useAddSubscriptionsCreditCard,
} from "../../../api/subscriptionApi";


interface CreditCardFormProps {
  amount: number;
  email: string;
  userId: string;
  subscribeId: number;
  onClose: () => void;
}

const CreditCardForm: React.FC<CreditCardFormProps> = ({ amount, email, subscribeId, onClose }) => {

  const [cardNumber, setCardNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [cvc, setCvc] = useState('');
  const [cardHolder, setCardHolder] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const [errors, setErrors] = useState({
    cardNumber: '',
    expiryDate: '',
    cvc: '',
    cardHolder: '',
    phoneNumber: '', 
  });

  const [isTab, setIsTab] = useState(true);

  useEffect(() => {
    const setWindowSize = () => {
      setIsTab(window.matchMedia("(max-width: 767px)").matches);
    };

    setWindowSize();

    window.addEventListener("resize", setWindowSize);

    return () => {
      window.removeEventListener("resize", setWindowSize);
    };
  }, []);

  const formatCardNumber = (value: string) => {
    return value.replace(/\s+/g, '').replace(/(\d{4})/g, '$1 ').trim();
  };

  const handleCardNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, '');
    if (value.length <= 16) {
      setCardNumber(formatCardNumber(value));
      setErrors(prev => ({ ...prev, cardNumber: '' })); 
    }
  };

  const handleExpiryDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, '');
    if (value.length <= 7) {
      setExpiryDate(value.replace(/(\d{2})(\d{4})/, '$1/$2'));
      setErrors(prev => ({ ...prev, expiryDate: '' })); 
    }
  };

  const handleCvcChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, '');
    if (value.length <= 3) {
      setCvc(value);
      setErrors(prev => ({ ...prev, cvc: '' })); 
    }
  };

  const handleCardHolderChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCardHolder(e.target.value.toUpperCase());
    setErrors(prev => ({ ...prev, cardHolder: '' })); 
  };

  const handlePhoneNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^+\d]/g, ''); 
    if (value.length <= 16) { 
      setPhoneNumber(value);
      setErrors(prev => ({ ...prev, phoneNumber: '' })); // Reset error on valid input
    }
  };

  const { mutate, data: subscriptionData } = useAddSubscriptionsCreditCard();
  

  useEffect(() => {
    if (subscriptionData && subscriptionData.status === "success") {
      if (isTab) {
        window.location.replace(subscriptionData.payment_link);
      } else {
        window.open(subscriptionData.payment_link, "_blank");
      }
      onClose();
    }
  }, [subscriptionData]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newErrors = {
      cardNumber: '',
      expiryDate: '',
      cvc: '',
      cardHolder: '',
      phoneNumber: '',
    };

    if (cardNumber.replace(/\s/g, '').length < 16) {
      newErrors.cardNumber = "Card Number must be at least 16 digits long.";
    }
    if (expiryDate.length < 7) { 
      newErrors.expiryDate = "Expiry Date must be in the format MM/YYYY.";
    }
    if (cvc.length < 3) {
      newErrors.cvc = "CVC must be at least 3 digits long.";
    }
    if (cardHolder.length < 3) {
      newErrors.cardHolder = "Cardholder Name must be at least 3 characters long.";
    }
    if (phoneNumber.length < 7 || phoneNumber.length > 15) { 
      newErrors.phoneNumber = "Phone Number must be between 7 and 15 digits long.";
    }

    if (newErrors.cardNumber || newErrors.expiryDate || newErrors.cvc || newErrors.cardHolder) {
      setErrors(newErrors);
      return;
    }

    mutate({
      amount,
      phone_number: phoneNumber,
      // user_name: email.split('@').shift(),
      user_name: cardHolder,
      number: cardNumber.replaceAll(' ', ''),
      exp_month: expiryDate.split('/').shift(),
      exp_year: expiryDate.split('/').pop(),
      secur_code: cvc,
      subscribe_id: subscribeId
    })
  };

  return (
    <form className={styles.form__credit_card} onSubmit={handleSubmit}>

      {/* Card Number */}
      <div>
        <label htmlFor="cardNumber">Card Number:</label>
        <Input
          type="text"
          id="cardNumber"
          name="cardNumber"
          value={cardNumber}
          onChange={handleCardNumberChange}
          placeholder="1234 5678 9012 3456"
          maxLength={19}
          minLength={19} 
          required
        />
        {errors.cardNumber && <p className={styles.error}>{errors.cardNumber}</p>}
      </div>

      <div className={styles.col__csv}>

        {/* Expiry Date */}
        <div>
          <label htmlFor="expiryDate">Expiry Date:</label>
          <Input
            type="text"
            id="expiryDate"
            name="expiryDate"
            value={expiryDate}
            onChange={handleExpiryDateChange}
            placeholder="MM/YYYY"
            maxLength={7}
            minLength={7} 
            required
          />
          {errors.expiryDate && <p className={styles.error}>{errors.expiryDate}</p>}
        </div>

        {/* CVC Input */}
        <div>
          <label htmlFor="cvc">CVC:</label>
          <Input
            type="password"
            id="cvc"
            name="cvc"
            value={cvc}
            onChange={handleCvcChange}
            placeholder="CVC"
            maxLength={3}
            minLength={3} 
            required
          />
          {errors.cvc && <p className={styles.error}>{errors.cvc}</p>}
        </div>
      </div>

      {/* Cardholder Name Input */}
      <div>
        <label htmlFor="cardHolder">Cardholder Name:</label>
        <Input
          type="text"
          id="cardHolder"
          name="cardHolder"
          value={cardHolder}
          onChange={handleCardHolderChange}
          placeholder="Cardholder Name"
          minLength={3} 
          required
        />
        {errors.cardHolder && <p className={styles.error}>{errors.cardHolder}</p>}
      </div>

      
      {/* Phone Number Input */}
      <div>
        <label htmlFor="phoneNumber">Phone Number:</label>
        <div className={styles.phoneInputContainer}>
          <span className={styles.phoneInputPrefix}>+</span>
          <Input
            type="text"
            id="phoneNumber"
            name="phoneNumber"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            placeholder="Phone Number"
            maxLength={15} 
            required
            style={{ marginLeft: '4px' }} 
          />
        </div>
        {errors.phoneNumber && <p className={styles.error}>{errors.phoneNumber}</p>}
      </div>

      <Button baseType="submit" text="Submit" />

    </form>
  );
};

export default CreditCardForm;
