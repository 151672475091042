import { ReactComponent as Add } from "../assets/add.svg";
import { ReactComponent as Coin } from "../../../assets/coin.svg";

import styles from "./Balance.module.scss";
import { useUserStore } from "../../../store/user";
import { useGetUserInfo } from "../../../api/userApi";

const Balance = () => {
  const isAuth = useUserStore((state) => state.isAuth);
  const user = useUserStore((state) => state.user);
  const setIsOpenedModalLogin = useUserStore(
    (state) => state.setIsOpenedModalLogin,
  );
  const setIsPayment = useUserStore((state) => state.setIsPayment);

  const handleAddBalance = () => {
    if (isAuth) {
      setIsPayment(true);
    } else {
      setIsOpenedModalLogin(true);
    }
  };

  return (
    <div className={styles.balance}>
      <div className={styles.balance__coins}>
        <Coin />
        {user?.coins || 0}
      </div>

      <div className={styles.balance__add} onClick={handleAddBalance}>
        <Add />
      </div>
    </div>
  );
};

export default Balance;
