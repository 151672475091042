import { ReactComponent as Facebook } from "../sideBar/assets/Facebook.svg";
import { ReactComponent as Instagram } from "../sideBar/assets/Instagram.svg";
import { ReactComponent as TikTok } from "../sideBar/assets/TikTok.svg";

import styles from "./socialMedia.module.scss";
import { useTranslation } from "react-i18next";

const SocialMedia = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.social__row}>
      <div className={styles.social}>
        <Facebook />
        <Instagram />
        <TikTok />
      </div>

      <p className={styles.social__disklamer}>{t("we_do_not")}</p>
    </div>
  );
};

export default SocialMedia;
