import { checkImageOnGeneration, checkPicture, generatePicture } from "./index";
import { useMutation } from "react-query";
import { queryClient } from "../index";

export const useGeneratePicture = () => {
  return useMutation({
    mutationFn: generatePicture,
    onSuccess: () => {
      queryClient.invalidateQueries("info");
    },
  });
};

export const useCheckPicture = () => {
  return useMutation({
    mutationFn: checkImageOnGeneration,
  });
};
