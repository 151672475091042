import avatar from "../../assets/avatar.png";
import { ReactComponent as Coin } from "../../assets/coin.svg";

import styles from "./UserInfo.module.scss";
import Button from "../common/button/Button";
import classNames from "classnames";
import SettingDownload from "../settingDownload/SettingDownload";
import { useUserStore } from "../../store/user";
import React, { useEffect, useState } from "react";
import { useNavigate } from "@tanstack/react-router";
import { useGetUserInfo } from "../../api/userApi";
import PictureHistory from "../pictureHistory/PictureHistory";
import { ReactComponent as Logout } from "../../assets/logout.svg";
import { useTranslation } from "react-i18next";

const UserInfo = () => {
  const isAuth = useUserStore((state) => state.isAuth);
  const setIsOpenedModalLogin = useUserStore(
    (state) => state.setIsOpenedModalLogin,
  );
  const navigate = useNavigate();
  const [isTab, setIsTab] = useState(false);
  const { data: user } = useGetUserInfo();
  const setIsPayment = useUserStore((state) => state.setIsPayment);
  const setIsAuth = useUserStore((state) => state.setIsAuth);
  const setUser = useUserStore((state) => state.setUser);
  const setIsCheckingAuth = useUserStore((state) => state.setIsCheckingAuth);
  const { t } = useTranslation();

  const handleAddBalance = () => {
    if (isAuth) {
      setIsPayment(true);
    } else {
      setIsOpenedModalLogin(true);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("refresh");
    localStorage.removeItem("access");
    localStorage.removeItem("taskId");
    localStorage.removeItem("cacheKey");
    navigate({ from: "/profile", to: "/" });
    setIsCheckingAuth(false);
    setIsAuth(false);
    setUser(null);
    window.location.reload();
  };

  useEffect(() => {
    const setWindowSize = () => {
      setIsTab(window.matchMedia("(max-width: 1080px)").matches);
    };

    setWindowSize();

    window.addEventListener("resize", setWindowSize);

    return () => {
      window.removeEventListener("resize", setWindowSize);
    };
  }, []);

  return (
    <div className={styles.info}>
      <div className={styles.info__header_between}>
        <div className={styles.info__header}>
          <img src={avatar} alt="" />

          <div className={styles.info__header_email}>
            {user?.email || "Email"}
          </div>

          <div className={styles.info__header_active}>active</div>
        </div>

        <div onClick={handleLogout}>
          <Logout />
        </div>
      </div>

      <div className={styles.info__grid}>
        <div className={styles.info__grid_item}>
          <h5 className={styles.info__grid_balance_title}>{t("balance")}</h5>

          <div className={styles.info__grid_balance}>
            <Coin width={43} height={43} />

            <p>
              <span>{user?.coins || 0}</span> gems
            </p>
          </div>

          <p className={styles.info__grid_balance_cost}>
            {t("1_generation_=")}
          </p>
        </div>

        <div
          className={classNames(
            styles.info__grid_item,
            styles.info__grid_item_active,
            styles.info__grid_free,
          )}
        >
          <div>
            <h6 className={styles.info__grid_balance_title}>
              {t("cool_new_feature")}
            </h6>

            <p className={styles.info__grid_free_text}>{t("now_you_can")}</p>
          </div>

          <Button
            text={"Try it now!"}
            color={"gray"}
            variant={"small"}
            onClick={() => navigate({ from: "/profile", to: "/" })}
          />
        </div>

        <div
          className={classNames(styles.info__grid_item, styles.info__grid_free)}
        >
          <div>
            <h6 className={styles.info__grid_free_title}>
              {t("get_coins_for")}
            </h6>

            <p className={styles.info__grid_free_text}>
              {t("complete_tasks_and")}
            </p>
          </div>

          <Button text={t("get_coins_for")} variant={"small"} />
        </div>
      </div>

      {isTab && (
        <div className={styles.tab}>
          <div className={styles.layout__row}>
            <div
              className={classNames(
                styles.layout__row_column,
                styles.info__grid_mobile,
              )}
            >
              <div
                className={classNames(
                  styles.info__grid_item,
                  styles.info__grid_item_active,
                  styles.info__grid_free,
                  styles.info__grid_mobile,
                )}
              >
                <div>
                  <h6 className={styles.info__grid_balance_title}>
                    {t("cool_new_feature")}
                  </h6>

                  <p className={styles.info__grid_free_text}>
                    {t("now_you_can")}
                  </p>
                </div>

                <Button
                  text={"Try it now!"}
                  color={"gray"}
                  variant={"small"}
                  onClick={() => navigate({ from: "/profile", to: "/" })}
                />
              </div>
            </div>

            <div className={styles.layout__row_column}>
              <Button
                text={t("buy_more_gems")}
                type={"straight"}
                onClick={handleAddBalance}
                variant={isTab ? "normal" : "big"}
              />
            </div>

            <div
              className={classNames(
                styles.layout__row_column,
                styles.layout__row_column_mobile,
              )}
            >
              <Button
                text={"Upload photo"}
                variant={"big"}
                onClick={() => navigate({ to: "/" })}
              />
            </div>

            <div className={styles.layout__row_column}>
              <SettingDownload />
            </div>
          </div>
        </div>
      )}

      <div className={styles.info__photos}>
        {user?.picture?.length === 0 && (
          <p className={styles.info__photos_none}>
            Your gallery is empty for now
          </p>
        )}

        <PictureHistory />
      </div>
    </div>
  );
};

export default UserInfo;
