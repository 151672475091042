import {createFileRoute, useNavigate, useParams} from "@tanstack/react-router";
import Footer from "../../component/footer/Footer";
import styles from "../../assets/styles/privacy.module.scss";
import {useConfirmEmail} from "../../api/userApi";
import React, {useEffect, useState} from "react";
import Main from "../../component/main/Main";
import Error from "../../component/modals/error/Error";
import ModalWrapper from "../../component/modals/ModalWrapper";
import Button from "../../component/common/button/Button";

// Определяем интерфейс для параметров маршрута
interface ConfirmMailParams {
    uid: string;
    token: string;
}

// @ts-ignore
export const Route = createFileRoute("/confirm_mail/:uid/:token/")({
    component: () => <ConfirmMail/>,
});

function ConfirmMail() {
    const { mutate: mutateConfirm, data: confirmData } = useConfirmEmail()
    // @ts-ignore
    const params = Route.useParams();

    useEffect(() => {
        try{
            mutateConfirm({"token": params.token, "uid": params.uid});
        }catch (e) {

        }
    }, []);

    useEffect(() => {
        if(confirmData?.status == 'success') {
            localStorage.setItem('access', confirmData['tokens'].access)
            localStorage.setItem('refresh', confirmData['tokens'].refresh)
        }
        setTimeout(function() {
            window.location.href = '/'
        }, 5000);
    }, [confirmData]);
    return (
        <>

            <ModalWrapper isModalOpened={true}>
                <div className={styles.error}>
                    <h3>{confirmData?.status == 'success' ? "Success" : "Error"}</h3>

                    <p>{confirmData?.status == 'success' ? "The email was successfully confirmed. You will be redirected to your personal account in a few seconds" : "Error"}</p>
                </div>
            </ModalWrapper>
        </>
    );
}

export default ConfirmMail;
