import { createFileRoute } from "@tanstack/react-router";
import Footer from "../../component/footer/Footer";
import styles from "../../assets/styles/privacy.module.scss";

// @ts-ignore
export const Route = createFileRoute("/mediaPress/")({
  component: mediaPress,
});

function mediaPress() {
  return (
    <div>
      <div className={styles.media}>
        <h1>For Media and Press</h1>

        <p>
          Got questions, comments, or requests? We’d love to hear from you!
          Reach out to us at pr@nuds.ai and we’ll get back to you as soon as
          possible.
        </p>
      </div>

      <Footer />
    </div>
  );
}

export default mediaPress;
