import styles from "./AvatarAndBalance.module.scss";
import Avatar from "./component/Avatar";
import Balance from "./component/Balance";

const AvatarAndBalance = () => {
  return (
    <div className={styles.avatarAndBalance}>
      <Avatar />

      <Balance />
    </div>
  );
};

export default AvatarAndBalance;
