import axios from 'axios'
import Cookies from "js-cookie";

export const axiosInstance = axios.create({
  baseURL: 'https://wsocket.nuds.ai',
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    credentials: 'include',
  },
  // withCredentials: true,
})

const axiosInstance1 = axios.create({
  baseURL: 'https://wsocket.nuds.ai',
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    credentials: 'include',
  },
  // withCredentials: true,
})

export const checkEmail = async (data: any) => {
  return (await axiosInstance.post('/api/registration-email', data)).data
}

export const confirmEmail = async (data: any) => {
  return (await axiosInstance.post('/product-api/register/confirm/', data)).data
}
export const registr = async (data: any) => {
  return (await axiosInstance.post('/product-api/register/', data)).data
}

export const login = async (data: any) => {
  return (await axiosInstance.post('/product-api/login/', data)).data
}

export const checkPassword = async (data: any) => {
  return (await axiosInstance.post('/api/login', data)).data
}

export const setPassword = async (data: any) => {
  return (await axiosInstance.post('/api/set-password', data)).data
}

export const setPasswordForgot = async (data: any) => {
  return (await axiosInstance.post('/api/set-forgot-password', data)).data
}

const refreshToken = async (data: any) => {
  return (await axiosInstance.post('/product-api/refresh-token/', data)).data
}

export const forgotPassword = async (data: any) => {
  return (await axiosInstance.post('/product-api/password/reset/', data)).data
}

export const googleAuth = async () => {
  return (await axiosInstance.get('/auth/google')).data
}

export const facebookAuth = async (data: any) => {
  return (await axiosInstance.post('/auth/facebook', data)).data
}

export const twitterAuth = async (data: any) => {
  return (await axiosInstance.post('/auth/twitter', data)).data
}

// ===============================

export const getUserInfo = async (data: any) => {
  return (await axiosInstance.get('/product-api/get-info', data)).data
}

export const googleSendCode = async (code: string) => {
  const subid = Cookies.get('subid')

  return (await axiosInstance.post(`/googleauth-callback/`, { token: code, subid: subid })).data
}

export const savePictureName = async (data: any) => {
  return (await axiosInstance.post('/save-image-name', data)).data
}

export const getFreeCoinUser = async (data: any) => {
  return (await axiosInstance.put('/product-api/get-free-coin/', data)).data
}

export const addUserBalance = async (data: any) => {
  return (await axiosInstance.post('/get-info/add-balance', data)).data
}

export const minusUserBalance = async (data: any) => {
  return (await axiosInstance.post('/get-info/minus-balance', data)).data
}

export const generatePicture = async (data: any) => {
  return (await axiosInstance.post('/generate_picture', data)).data
}

export const checkPicture = async (id: any) => {
  return (await axiosInstance.post('/check_picture', id)).data
}

export const imgHistory = async () => {
  return (await axiosInstance.get('/product-api/image/history/')).data
}

export const getDresses = async () => {
  return (await axiosInstance.get('/product-api/dresses/')).data
}

export const getSubscriptions = async () => {
  return (await axiosInstance.get('/product-api/get-info/subscriptions/')).data
}

export const getProject = async () => {
  return (await axiosInstance.get('/product-api/get-info/project-id/')).data
}

export const getPaymentTypeId = async (params: any) => {
  const response = await axiosInstance.get('http://api.godzipay.com/dev/card/getToken/', {
    params,
  })
  return response.data
}

export const buySubscriptions = async (body: any) => {
  return (await axiosInstance.post('/product-api/crypto/create-payment/', body)).data
}

export const buySubscriptionsGiftpay = async (body: any) => {
  return (await axiosInstance.post('/product-api/giftpay/create-payment/', body)).data
}

export const buySubscriptionsStripe = async (body: any) => {
  return (await axios.post('https://coderx.pro/coder/stripe-pay', body)).data
}

export const buySubscriptionsCreditCard = async (body: any) => {
  return (await axiosInstance.post('/product-api/godzi/create-payment/', body)).data
}

export const checkImageOnGeneration = async (body: any) => {
  return (await axiosInstance.post('/product-api/task-info/', body)).data
}

export const getTeachCount = async () => {
  return (await axiosInstance.patch('/product-api/teach-coin/')).data
}

axiosInstance.interceptors.request.use((config: any) => {
  if (localStorage.getItem('access')) config.headers.authorization = `Bearer ${localStorage.getItem('access')}`

  return config
}, null)

let flag = false
axiosInstance.interceptors.response.use(
  function (response) {
    return response
  },
  async function (error) {
    const originalRequest = error.config

    if (error.response?.status === 401 && !flag) {
      if (localStorage.getItem('refresh')) {
        flag = true
        try {
          const tokens = await refreshToken({
            refresh: localStorage.getItem('refresh'),
          })

          if (tokens.access) {
            localStorage.setItem('access', tokens.access)

            return axios(originalRequest)
          }
        } catch (e) {
          localStorage.removeItem('access')
          localStorage.removeItem('refresh')
          window.location.reload()
        }
      }
    }

    return Promise.reject(error)
  }
)
