import { createFileRoute } from "@tanstack/react-router";

import Footer from "../../component/footer/Footer";

import styles from "../../assets/styles/privacy.module.scss";

// @ts-ignore
export const Route = createFileRoute("/privacy/")({
  component: Privacy,
});

function Privacy() {
  return (
    <div>
      <div className={styles.privacy}>
        <h1>Privacy Policy</h1>

        <p>Last updated: July 15th, 2024</p>

        <p>
          This Privacy Policy describes our policies and procedures regarding
          the collection, use, and disclosure of your information when you use
          the Service and describes your privacy rights and how the law protects
          you. This section explains. We use your personal information to
          provide and improve the service. By using the Service, you consent to
          the collection and use of information in accordance with this Privacy
          Policy.
        </p>

        <h2>Interpretation & Definitions</h2>

        <p>In accordance with these Terms & Conditions:</p>

        <ul>
          <li>
            Account means a unique account created for you to access our Service
            or part of our Service.
          </li>
          <li>The United States is referred to as the Country.</li>
          <li>
            Company refers to nuds.ai (also referred to as "the Company," "We,"
            "Us," or "Our" in this Agreement).
          </li>
          <li>
            Device refers to any item that can access the Service, including a
            computer, a phone, or a tablet computer.
          </li>
          <li>
            A cookie is a small file that a website places on your computer,
            mobile device, or other device that contains details of, among other
            things, your browsing history on that website.
          </li>
          <li>
            Personal data is any information relating to an identified or
            identifiable person.
          </li>
          <li>The Website is referred to as the Service.</li>
          <li>
            Service Provider means a natural or legal person who processes data
            on behalf of the Company. To facilitate the Service, provide the
            Service on our behalf, perform services related to the Service, or
            assist us in analyzing how the Service is used, refers to
            third-party companies or individuals employed by us.
          </li>
          <li>
            Usage Data refers to automatically collected data generated through
            the use of the Service or by the Service infrastructure itself
            (e.g., page visit time).
          </li>
          <li>The Website is nuds.ai. You can access it at nuds.ai.</li>
          <li>
            You refer to the person accessing or using the Service, or nuds.ai
            or any other entity on behalf of that person accessing or using the
            Service, as applicable.
          </li>
        </ul>

        <h2>Personal Data Collection and Usage</h2>

        <p>
          While using our Service, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you. Personally identifiable information includes, but is not
          limited to: Usage Data.
        </p>

        <h3>Usage Data Collection and Usage</h3>

        <p>
          Usage Data is collected automatically when you use the Service. Usage
          Data may include information such as your device's Internet Protocol
          address (e.g., your IP address), browser type, browser version, the
          pages of the Service that you visit, the time and date of your visit,
          the time spent on those pages, unique device identifiers, and other
          diagnostic data. If you access the Service via a mobile device, the
          type of mobile device you are using, your mobile device unique ID,
          your mobile device IP address, your mobile phone operating system, the
          type of mobile internet browser you are using, unique device
          identifiers, and other diagnostic data. We may also collect
          information that your browser sends when you access our Services or
          when you use or through your mobile device.
        </p>

        <h3>Tracking Technologies and Cookies</h3>

        <ul>
          <li>
            Cookies or browser cookies: Cookies are small files placed on your
            device. You can refuse all cookies or instruct your browser to
            indicate that cookies are being sent. However, if you do not accept
            cookies, you may not be able to use some of our services. Our
            services may use cookies unless your browser is set to refuse
            cookies.
          </li>
          <li>
            Web beacon: Certain areas of our Service and emails may contain
            small electronic files called web beacons (also known as clear GIFs,
            pixel tags, and single-pixel GIFs). These allow the Company to count
            users who have visited those pages or opened an email and for other
            related website statistics (for example, recording the popularity of
            certain sections and checking the integrity of our systems and
            servers).
          </li>
        </ul>

        <p>
          Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
          remain on Your personal computer or mobile device when You go offline,
          while Session Cookies are deleted as soon as You close Your web
          browser. You can learn more about cookies on TermsFeed website
          article. We use both Session and Persistent Cookies for the purposes
          set out below:
        </p>

        <ul>
          <li>
            Necessary / Essential Cookies Type: Session Cookies Administered by:
            Us Purpose: These Cookies are essential to provide You with services
            available through the Website and to enable You to use some of its
            features. They help to authenticate users and prevent fraudulent use
            of user accounts. Without these Cookies, the services that You have
            asked for cannot be provided, and We only use these Cookies to
            provide You with those services.
          </li>
          <li>
            Cookies Policy / Notice Acceptance Cookies Type: Persistent Cookies
            Administered by: Us Purpose: These Cookies identify if users have
            accepted the use of cookies on the Website.
          </li>
          <li>
            Functionality Cookies Type: Persistent Cookies Administered by: Us
            Purpose: These Cookies allow us to remember choices You make when
            You use the Website, such as remembering your login details or
            language preference. The purpose of these Cookies is to provide You
            with a more personal experience and to avoid You having to re-enter
            your preferences every time You use the Website.
          </li>
        </ul>

        <p>
          For more information about the cookies we use and your choices
          regarding cookies, please visit our Cookies Policy or the Cookies
          section of our Privacy Policy.
        </p>

        <h2>Use of Your Personal Data</h2>

        <p>The Company may use Personal Data for the following purposes:</p>

        <ul>
          <li>
            To provide and maintain our Service, including to monitor the usage
            of our Service.
          </li>
          <li>
            To manage Your Account: to manage Your registration as a user of the
            Service. The Personal Data You provide can give You access to
            different functionalities of the Service that are available to You
            as a registered user.
          </li>
          <li>
            For the performance of a contract: the development, compliance, and
            undertaking of the purchase contract for the products, items, or
            services You have purchased or of any other contract with Us through
            the Service.
          </li>
          <li>
            To contact You: To contact You by email, telephone calls, SMS, or
            other equivalent forms of electronic communication, such as a mobile
            application's push notifications regarding updates or informative
            communications related to the functionalities, products or
            contracted services, including the security updates, when necessary
            or reasonable for their implementation.
          </li>
          <li>
            To provide You with news, special offers, and general information
            about other goods, services, and events which we offer that are
            similar to those that you have already purchased or enquired about
            unless You have opted not to receive such information.
          </li>
          <li>
            To manage Your requests: To attend and manage Your requests to Us.
          </li>
          <li>
            For other purposes: We may use Your information for other purposes,
            such as data analysis, identifying usage trends, determining the
            effectiveness of our promotional campaigns, and to evaluate and
            improve our Service, products, services, marketing, and your
            experience.
          </li>
        </ul>

        <p>
          We may share Your personal information in the following situations:
        </p>

        <ul>
          <li>
            With Service Providers: We may share Your personal information with
            Service Providers to monitor and analyze the use of our Service, to
            contact You.
          </li>
          <li>
            For business transfers: We may share or transfer Your personal
            information in connection with, or during negotiations of, any
            merger, sale of Company assets, financing, or acquisition of all or
            a portion of Our business to another company.
          </li>
          <li>
            With Affiliates: We may share Your information with Our affiliates,
            in which case we will require those affiliates to honor this Privacy
            Policy. Affiliates include Our parent company and any other
            subsidiaries, joint venture partners, or other companies that We
            control or that are under common control with Us.
          </li>
          <li>
            With business partners: We may share Your information with Our
            business partners to offer You certain products, services, or
            promotions.
          </li>
          <li>
            With other users: when You share personal information or otherwise
            interact in the public areas with other users, such information may
            be viewed by all users and may be publicly distributed outside.
          </li>
          <li>
            With Your consent: We may disclose Your personal information for any
            other purpose with Your consent.
          </li>
        </ul>

        <h2>Retention of Your Personal Data</h2>

        <p>
          The Company will retain Your Personal Data only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use Your Personal Data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and
          enforce our legal agreements and policies. The Company will also
          retain Usage Data for internal analysis purposes. Usage Data is
          generally retained for a shorter period of time, except when this data
          is used to strengthen the security or to improve the functionality of
          Our Service, or We are legally obligated to retain this data for
          longer time periods. We do not store or publish user data. All images
          will be automatically deleted within 48 hours.
        </p>

        <h2>Retention of Your Personal Data</h2>

        <p>
          Your information, including Personal Data, is processed at the
          Company's operating offices and in any other places where the parties
          involved in the processing are located. It means that this information
          may be transferred to — and maintained on — computers located outside
          of Your state, province, country, or other governmental jurisdiction
          where the data protection laws may differ from those from Your
          jurisdiction. Your consent to this Privacy Policy followed by Your
          submission of such information represents Your agreement to that
          transfer. The Company will take all steps reasonably necessary to
          ensure that Your data is treated securely and in accordance with this
          Privacy Policy and no transfer of Your Personal Data will take place
          to an organization or a country unless there are adequate controls in
          place including the security of Your data and other personal
          information.
        </p>

        <h2>Disclosure of Your Personal Data</h2>

        <h3>Business Transactions</h3>

        <p>
          If the Company is involved in a merger, acquisition, or asset sale,
          Your Personal Data may be transferred. We will provide notice before
          Your Personal Data is transferred and becomes subject to a different
          Privacy Policy.
        </p>

        <h3>Law Enforcement</h3>

        <p>
          Under certain circumstances, the Company may be required to disclose
          Your Personal Data if required to do so by law or in response to valid
          requests by public authorities (e.g., a court or a government agency).
        </p>

        <h3>Other Legal Requirements</h3>

        <p>
          The Company may disclose Your Personal Data in the good faith belief
          that such action is necessary to:
        </p>

        <ul>
          <li>Comply with a legal obligation</li>
          <li>Protect and defend the rights or property of the Company</li>
          <li>
            Prevent or investigate possible wrongdoing in connection with the
            Service
          </li>
          <li>
            Protect the personal safety of Users of the Service or the public
          </li>
          <li>Protect against legal liability</li>
        </ul>

        <h2>Security of Your Personal Data</h2>

        <p>
          The security of Your Personal Data is important to Us, but remember
          that no method of transmission over the Internet, or method of
          electronic storage, is 100% secure. While We strive to use
          commercially acceptable means to protect Your Personal Data, We cannot
          guarantee its absolute security.
        </p>

        <h2>Children's Privacy</h2>

        <p>
          Our Service does not address anyone under the age of 18. We do not
          knowingly collect personally identifiable information from anyone
          under the age of 18. If You are a parent or guardian and You are aware
          that Your child has provided Us with Personal Data, please contact Us.
          If We become aware that We have collected Personal Data from anyone
          under the age of 18 without verification of parental consent, We take
          steps to remove that information from Our servers. If We need to rely
          on consent as a legal basis for processing Your information and Your
          country requires consent from a parent, We may require Your parent's
          consent before We collect and use that information.
        </p>

        <h2>Links to Other Websites</h2>

        <p>
          Our Service may contain links to other websites that are not operated
          by Us. If You click on a third-party link, You will be directed to
          that third party's site. We strongly advise You to review the Privacy
          Policy of every site You visit. We have no control over and assume no
          responsibility for the content, privacy policies, or practices of any
          third-party sites or services.
        </p>

        <h2>Changes to this Privacy Policy</h2>

        <p>
          We may update Our Privacy Policy from time to time. We will notify You
          of any changes by posting the new Privacy Policy on this page. We will
          let You know via email and/or a prominent notice on Our Service, prior
          to the change becoming effective and update the "Last updated" date at
          the top of this Privacy Policy. You are advised to review this Privacy
          Policy periodically for any changes. Changes to this Privacy Policy
          are effective when they are posted on this page.
        </p>

        <h2>Contact Us</h2>

        <p>
          If you have any questions about this Privacy Policy, You can contact
          us:
        </p>

        <ul>
          <li>By email: support@nuds.ai</li>
        </ul>
      </div>

      <Footer />
    </div>
  );
}

export default Privacy;
