import ModalWrapper from '../ModalWrapper'

import Button from '../../common/button/Button'
import Input from '../../common/input/Input'

import styles from './Check.module.scss'
import { FC, useEffect, useState } from 'react'
import { useForgotPasswordUser, useGoogleAuth, useLoginUser, useRegisterUser } from '../../../api/userApi'
import Countdown from 'react-countdown'
import GetRequestLinkTimer from '../../getRequestLinkTimer/GetRequestLinkTimer'
import { useUserStore } from '../../../store/user'
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google'
import { useQuery } from 'react-query'
import { getUserInfo } from '../../../api'
import Cookies from "js-cookie";

const Check: FC<any> = ({ isModalOpened, hideModal, setTextOpenError, setIsOpenError, setTextOpenSuccess, setIsOpenSuccess }) => {
  const { mutate, data, reset, status } = useLoginUser()
  const { mutate: mutateRegister, data: dataRegister, status: statusRegister, error, reset: resetREgister } = useRegisterUser()
  const { mutate: mutateForgot } = useForgotPasswordUser()
  const { mutate: mutateGoogle, data: dataGoogle } = useGoogleAuth()
  const setIsAuth = useUserStore((state) => state.setIsAuth)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [repassword, setRepassword] = useState('')
  const [errorPassword, setErrorPassword] = useState('')
  const [isRegister, setIsRegister] = useState(true)
  const [requestButtonActive, setRequestButtonActive] = useState(false)
  const setUser = useUserStore((state) => state.setUser)

  const [enabled, setEnabled] = useState(false)
  const { data: userData } = useQuery('info1', getUserInfo, {
    retry: false,
    enabled,
  })

  const handleForgotPassword = () => {
    mutateForgot({ email })
  }

  useEffect(() => {
    if (dataGoogle) {
      localStorage.setItem('access', dataGoogle.access)
      localStorage.setItem('refresh', dataGoogle.refresh)

      setEnabled(true)

      setIsAuth(true)
      hideModal && hideModal()
    }
  }, [dataGoogle])

  useEffect(() => {
    if (userData) {
      setUser(userData)
    }
  }, [userData])

  const handleSubmit = (e: any) => {
    e.preventDefault()
    setRequestButtonActive(false)
    const subid = Cookies.get('subid')
    if (isRegister) {
      if (repassword !== password) {
        setErrorPassword('Password is different')
        return
      } else {
        setErrorPassword('')
      }
      mutateRegister({ email, password, sub_id: subid })
    } else {
      mutate({ email, password })
    }
  }

  useEffect(() => {
    if (data) {
      hideModal && hideModal()
    }
  }, [data, hideModal])

  useEffect(() => {
    if (error) {
      hideModal && hideModal()
      try {

        if (
            //@ts-ignore
            error?.response?.data?.email[0] === 'user with this email already exists.'
        ) {
          setTextOpenError('User with this email already exists.')
          setIsOpenError(true)
          resetREgister()
        }
      }catch (e) {
        setTextOpenError('User with this email already exists.')
        setIsOpenError(true)
        resetREgister()
        // To confirm you registration, please check your email
      }
    }
  }, [error, hideModal])

  useEffect(() => {
    if (data && status === 'success') {
      // localStorage.setItem('access', data.access)
      // localStorage.setItem('refresh', data.refresh)
      // setIsAuth(true)
      setTextOpenError('To confirm you registration, please check your email')
      setIsOpenError(true)

      hideModal && hideModal()
    }
  }, [data, status, setIsAuth, hideModal])

  useEffect(() => {
    console.log(dataRegister)
    if (dataRegister && statusRegister === 'success') {
      // localStorage.setItem('access', dataRegister.tokens.access)
      // localStorage.setItem('refresh', dataRegister.tokens.refresh)
      // setIsAuth(true)
      setTextOpenError('To confirm you registration, please check your email')
      setIsOpenError(true)
      hideModal && hideModal()
    }
  }, [dataRegister, status, setIsAuth, hideModal])

  return (
    <ModalWrapper isModalOpened={isModalOpened} hideModal={hideModal}>
      <div className={styles.login}>
        <h5>{isRegister ? 'Registration' : 'Log in'}</h5>

        {!data?.message && typeof data !== 'string' && (
          <div>
            <form onSubmit={handleSubmit}>
              <div className={styles.login__email}>
                <Input value={email} placeholder={'Email'} setValue={setEmail} />
              </div>

              <div className={styles.login__email}>
                <Input value={password} placeholder={'Password'} setValue={setPassword} type={'password'} />
              </div>

              {isRegister ? (
                <div className={styles.login__email}>
                  <Input value={repassword} placeholder={'Repeat password'} setValue={setRepassword} type={'password'} />

                  <p className={styles.login__email_error}>{errorPassword}</p>
                </div>
              ) : (
                ''
              )}

              <div className={styles.login__button}>
                <Button disabled={( (email == null || email == "") || (password == null || password == "") || (repassword == null || repassword == ""))} text={isRegister ? 'Registration with Email' : 'Log in with Email'} />
              </div>
            </form>

            {!isRegister && (
              <div className={styles.login__forgot}>
                <button type={'button'} onClick={handleForgotPassword}>
                  Forgot password?
                </button>
              </div>
            )}

            <p className={styles.login__social}>Or log in with</p>

            <div className={styles.login__social_row}>
              <GoogleOAuthProvider clientId='1077386199179-tjgg4oh8893d7828s5glc70k13qp2720.apps.googleusercontent.com'>
                <GoogleLogin
                  onSuccess={(credentialResponse) => {
                    mutateGoogle(credentialResponse.credential!.toString())
                  }}
                  onError={() => {
                    console.log('Login Failed')
                  }}
                />
              </GoogleOAuthProvider>
            </div>

            {isRegister ? (
              <p className={styles.login__social}>
                If you have account you can <span onClick={() => setIsRegister(false)}>login</span>
              </p>
            ) : (
              <p className={styles.login__social}>
                If you do not have account you can <span onClick={() => setIsRegister(true)}>create</span>
              </p>
            )}
          </div>
        )}

        {typeof data === 'string' && (
          <div>
            <form onSubmit={handleSubmit}>
              <div className={styles.login__email}>
                <Input value={email} placeholder={'Email'} setValue={setEmail} />
              </div>

              <p className={styles.login__social}>
                <Countdown date={Date.now() + 60 * 1000} renderer={GetRequestLinkTimer} onComplete={() => setRequestButtonActive(true)} />
              </p>

              <div className={styles.login__button}>
                <Button text={'Request link'} disabled={!requestButtonActive} />
              </div>
            </form>
          </div>
        )}
      </div>
    </ModalWrapper>
  )
}

export default Check
