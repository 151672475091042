import { createFileRoute, useNavigate } from "@tanstack/react-router";

import SideBar from "../../component/sideBar/SideBar";
import Footer from "../../component/footer/Footer";
import UserInfo from "../../component/userInfo/UserInfo";

import styles from "../../assets/styles/profilePage.module.scss";
import Button from "../../component/common/button/Button";
import { useUserStore } from "../../store/user";
import GetFreeCoins from "../../component/getFreeCoins/GetFreeCoins";

import React, { useEffect } from "react";
import SocialMedia from "../../component/socialMedia/socialMedia";

export const Route = createFileRoute("/profile/")({
  component: Profile,
});

function Profile() {
  const isAuth = useUserStore((state) => state.isAuth);
  const isCheckingAuth = useUserStore((state) => state.isCheckingAuth);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuth && !isCheckingAuth) {
      navigate({ to: "/" });
    }
  }, [isAuth, isCheckingAuth, navigate]);

  const handleRefill = () => {
    navigate({ to: "/" });
  };

  return (
    <div className={styles.layout}>
      <div>
        <UserInfo />

        <div className={styles.tab}>
          <div className={styles.upload_button}>
            <Button
              text={"Upload photo"}
              variant={"big"}
              onClick={handleRefill}
            />
          </div>

          <div className={styles.get_free}>{/*<GetFreeCoins />*/}</div>

          <SocialMedia />
        </div>

        <Footer />
      </div>

      <SideBar profile />
    </div>
  );
}

export default Profile;
