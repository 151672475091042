import { useMutation, useQuery } from "react-query";
import {
  buySubscriptions,
  buySubscriptionsGiftpay,
  buySubscriptionsStripe,
  buySubscriptionsCreditCard,
  getSubscriptions,
  getProject,
  getPaymentTypeId,
} from "./index";

export const useGetSubscriptions = (refresh: any) => {
  return useQuery("getSubscriptions", getSubscriptions, {
    retry: false,
    enabled: !!refresh,
  });
};

export const useGetProject = () => {
  return useQuery("getProject", getProject);
};

export const useGetPaymentTypeId = (queryParams: any) => {
  return useQuery(['getPaymentTypeId', queryParams], () => getPaymentTypeId(queryParams), {
    enabled: !!queryParams, 
  });
};

export const useAddSubscriptions = () => {
  return useMutation({ mutationFn: buySubscriptions });
};

export const useAddSubscriptionsGiftpay = () => {
  return useMutation({ mutationFn: buySubscriptionsGiftpay });
};

export const useAddSubscriptionsStripe = () => {
  return useMutation({ mutationFn: buySubscriptionsStripe });
};

export const useAddSubscriptionsCreditCard = () => {
  return useMutation({ mutationFn: buySubscriptionsCreditCard });
};