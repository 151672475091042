import ModalWrapper from "../ModalWrapper";
import { FC, useEffect, useState } from "react";
import { ModalInterface } from "../../../interface/component/modals/modalsInterface";
import styles from "./Payment.module.scss";
import {
  useAddSubscriptions,
  useAddSubscriptionsGiftpay,
  useAddSubscriptionsStripe,
  useGetSubscriptions,
} from "../../../api/subscriptionApi";
import Switcher from "../../common/switcher/Switcher";
import { Link } from "@tanstack/react-router";
import Button from "../../common/button/Button";
import classNames from "classnames";
import { useUserStore } from "../../../store/user";

import { ReactComponent as Payment1 } from "./assets/_105553214844384.svg";
import { ReactComponent as Payment3 } from "./assets/Cripto.svg";
import { ReactComponent as Payment4 } from "./assets/Frame 1597880928-1.svg";
import { ReactComponent as Payment5 } from "./assets/Frame 1597880928.svg";
import { useTranslation } from "react-i18next";
import CreditCardForm from "../../forms/CreditCardForm/CreditCardForm";

const Payment: FC<ModalInterface> = ({ hideModal, isModalOpened }) => {
  const [isSwitch, setIsSwitch] = useState(true);
  const { mutate, data: subscriptionData } = useAddSubscriptions();
  const { mutate: mutateGiftpay, data: dataGiftpay } =
    useAddSubscriptionsGiftpay();
  const { mutate: mutateStripe, data: dataStripe } =
    useAddSubscriptionsStripe();
  const user = useUserStore((state) => state.user);
  const [active, setActive] = useState<any>({
    coins: 20000,
    enable_premium: false,
    enable_redress: true,
    id: 3,
    name: "Gold",
    price: 49.99,
  });
  const [isTab, setIsTab] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const { data } = useGetSubscriptions(refresh);

  const [type, setType] = useState("choose");
  const [activePaymentMethod, setActivePaymentMethod] = useState<any>();
  const { t } = useTranslation();

  const [showCreditCardForm, setShowCreditCardForm] = useState(false);

  useEffect(() => {
    const setWindowSize = () => {
      setIsTab(window.matchMedia("(max-width: 767px)").matches);
    };

    setWindowSize();

    window.addEventListener("resize", setWindowSize);

    return () => {
      window.removeEventListener("resize", setWindowSize);
    };
  }, []);

  useEffect(() => {
    if (isModalOpened) {
      setRefresh(true);
    } else {
      setRefresh(false);
      setType("choose");
      setShowCreditCardForm(false);
      setActivePaymentMethod(null);
      setActive(null);
    }
  }, [isModalOpened]);

  const handlePay = () => {
    if (active) {
      if (type === "choose") {
        setType("finish");
      } else {
        const temp = data?.find((item: any) => item.id === active.id);

        if (temp && activePaymentMethod) {
          if (activePaymentMethod === 2) {
            mutateGiftpay({
              amount: temp?.price,
              email: user?.email,
              subscribe_id: active.id,
            });
          } else if (activePaymentMethod === 3) {
            mutate({
              amount: temp?.price,
              email: user?.email,
              subscribe_id: active.id,
            });
          } else if (activePaymentMethod === 5 || activePaymentMethod === 6) {
            setShowCreditCardForm(true);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (subscriptionData && subscriptionData.status === "success") {
      if (isTab) {
        window.location.replace(subscriptionData.payment_link);
      } else {
        window.open(subscriptionData.payment_link, "_blank");
      }
      hideModal && hideModal();
      setActivePaymentMethod(null);
      setActive(null);
    }
  }, [subscriptionData]);

  useEffect(() => {
    if (dataGiftpay && dataGiftpay.status === "success") {
      if (isTab) {
        window.location.replace(dataGiftpay.payment_link);
      } else {
        window.open(dataGiftpay.payment_link, "_blank");
      }
      hideModal && hideModal();
      setActivePaymentMethod(null);
      setActive(null);
    }
  }, [dataGiftpay]);

  useEffect(() => {
    if (dataStripe && dataStripe.status === "success") {
      if (isTab) {
        window.location.replace(dataStripe.payment_link);
      } else {
        window.open(dataStripe.payment_link, "_blank");
      }
      hideModal && hideModal();
      setActivePaymentMethod(null);
      setActive(null);
    }
  }, [dataStripe]);

  const getIncludes = (item: any) => {
    if (!item.enable_premium && !item.enable_redress) return t("500_gems_");
    else return t("undress_and_fetish");
  };

  return (
    <ModalWrapper isModalOpened={isModalOpened} hideModal={hideModal}>
      <div className={styles.payment}>
        <h5>{type === "choose" ? t("choose_a_tariff") : t("checkout")}</h5>

        {showCreditCardForm ? (
          <CreditCardForm
            amount={active.price}
            email={user?.email}
            userId={user?.id}
            subscribeId={active.id}
            onClose={() => setShowCreditCardForm(false)}
          />
        ) : type === "choose" ? (
          <>
            <div className={styles.payment__type}>
              <p className={classNames({ [styles.inactive]: isSwitch })}>
                {t("base")}
              </p>

              <Switcher isSwitch={isSwitch} setIsSwitch={setIsSwitch} />

              <p className={classNames({ [styles.inactive]: !isSwitch })}>
                {t("premium")}
              </p>
            </div>

            <div>
              {data
                ?.slice(!isSwitch ? 0 : 2, !isSwitch ? 2 : 4)
                .map((item: any) => (
                  <div
                    key={item.id}
                    className={classNames(styles.payment__item, {
                      [styles.payment__item_active]: item.id === active?.id,
                    })}
                    onClick={() => setActive(item)}
                  >
                    <div
                      className={classNames(styles.payment__item_checkbox, {
                        [styles.payment__item_checkbox_active]:
                          item.id === active?.id,
                      })}
                    ></div>

                    <div className={styles.payment__item_full}>
                      <div className={styles.payment__item_header}>
                        <p>{item.name}</p>
                        <p>$ {item.price}</p>
                      </div>

                      <div className={styles.payment__item_info}>
                        {item.coins} gems | {getIncludes(item)}
                      </div>
                    </div>
                  </div>
                ))}
            </div>

            {!isSwitch && (
              <p className={styles.payment__info}>{t("not_enough_and")}</p>
            )}

            {isSwitch && (
              <p className={styles.payment__info}>{t("subscribe_to_our")}</p>
            )}
          </>
        ) : (
          <>
            <div className={styles.payment__between}>
              <div>
                <p>{t("tarif")}</p>
              </div>

              <div>
                <p>{active.name}</p>
                <span>{active.coins} gems</span>
                <span>{getIncludes(active)}</span>
              </div>
            </div>

            <div className={styles.payment__between}>
              <div>
                <p>{t("total")}</p>
              </div>

              <div>
                <p>${active.price}</p>
              </div>
            </div>

            <div className={styles.payment__method}>
              <p className={styles.payment__method_title}>
                {t("available_payment_methods")}
              </p>

              <p className={styles.payment__method_text}>
                {t("please_note_that")}
              </p>

              <div className={styles.payment__methods}>
                <div
                  className={classNames(styles.payment__methods_item, {
                    [styles.payment__methods_active]: activePaymentMethod === 2,
                  })}
                  onClick={() => setActivePaymentMethod(2)}
                >
                  <Payment1 />
                </div>

                <div
                  className={classNames(styles.payment__methods_item, {
                    [styles.payment__methods_active]: activePaymentMethod === 3,
                  })}
                  onClick={() => setActivePaymentMethod(3)}
                >
                  <Payment3 />
                </div>

                <div
                  className={classNames(
                    styles.payment__methods_item,
                    styles.payment__methods_item_addition,
                    {
                      [styles.payment__methods_active]:
                        activePaymentMethod === 5,
                    },
                  )}
                  onClick={() => setActivePaymentMethod(5)}
                >
                  <Payment5 />
                </div>

                <div
                  className={classNames(
                    styles.payment__methods_item,
                    styles.payment__methods_item_addition,
                    {
                      [styles.payment__methods_active]:
                        activePaymentMethod === 6,
                    },
                  )}
                  onClick={() => setActivePaymentMethod(6)}
                >
                  <Payment4 />
                </div>
              </div>
            </div>

            <p className={styles.payment__info}>{t("subscribe_to_our")}</p>
          </>
        )}

        {!showCreditCardForm && (
          <div>
            <Button
              text={type === "choose" ? t("next") : t("pay")}
              onClick={handlePay}
            />
          </div>
        )}

        <div className={styles.payment__terms}>
          <Link to={"/termsOfService"} target={"_blank"}>
            {t("term_of_use")}
          </Link>{" "}
          |{" "}
          <Link to={"/privacy"} target={"_blank"}>
            {t("privacy_policy")}
          </Link>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default Payment;
