import { ReactComponent as Regenerate } from "./assets/Arrows_Reload_01.svg";

import styles from "./RegenerateButton.module.scss";
import { FC } from "react";
import classNames from "classnames";

const RegenerateButton: FC<{
  onRegenerate: () => void;
  variant?: string;
  disabled: boolean;
}> = ({ onRegenerate, variant = "big", disabled }) => {
  return (
    <button
      className={classNames(styles.button, {
        [styles.button__big]: variant === "big",
        [styles.button__small]: variant === "small",
        [styles.button__disabled]: disabled,
      })}
      onClick={onRegenerate}
      disabled={disabled}
    >
      <Regenerate />
    </button>
  );
};

export default RegenerateButton;
