import {
  useMutation,
  useQuery,
} from 'react-query'
import {
    checkEmail,
    checkPassword, confirmEmail,
    forgotPassword,
    getDresses,
    getFreeCoinUser,
    getTeachCount,
    getUserInfo,
    googleSendCode,
    imgHistory,
    login,
    registr,
    savePictureName,
    setPassword,
    setPasswordForgot,
} from './index'
import { queryClient } from '../index'

export const useCheckEmailUser =
  () => {
    return useMutation(
      checkEmail
    )
  }

export const useLoginUser =
  () => {
    return useMutation(
      {
        mutationFn:
          login,
      }
    )
  }



export const useRegisterUser =
  () => {
    return useMutation(
      {
        mutationFn:
          registr,
      }
    )
  }

  export const useConfirmEmail =
  () => {
    return useMutation(
      {
        mutationFn:
            confirmEmail,
      }
    )
  }

export const useSetPasswordUser =
  () => {
    return useMutation(
      {
        mutationFn:
          setPassword,
      }
    )
  }

export const useSetPasswordForgotUser =
  () => {
    return useMutation(
      {
        mutationFn:
          setPasswordForgot,
      }
    )
  }
export const useGetTeachCount =
  () => {
    return useMutation(
      {
        mutationFn:
          getTeachCount,
      }
    )
  }

export const useForgotPasswordUser =
  () => {
    return useMutation(
      {
        mutationFn:
          forgotPassword,
      }
    )
  }

export const useCheckPasswordUser =
  () => {
    return useMutation(
      {
        mutationFn:
          checkPassword,
      }
    )
  }

export const useSaveUserPictureName =
  () => {
    return useMutation(
      {
        mutationFn:
          savePictureName,
      }
    )
  }

export const useGetUserInfo =
  () => {
    return useQuery(
      'info1',
      getUserInfo
    )
  }

export const useGoogleAuth =
  () => {
    return useMutation(
      {
        mutationFn:
          googleSendCode,
      }
    )
  }

export const useGetImgHistory =
  () => {
    return useQuery(
      'info2',
      imgHistory
    )
  }

export const useGetDresses =
  () => {
    return useQuery(
      'getDresses',
      getDresses
    )
  }

export const useFreeCoinUser =
  () => {
    return useMutation(
      {
        mutationFn:
          getFreeCoinUser,
        onSuccess:
          () => {
            queryClient.invalidateQueries(
              'info1'
            )
          },
      }
    )
  }
