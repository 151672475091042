import { FC } from "react";
import ModalWrapper from "../ModalWrapper";
import styles from "./Error.module.scss";
import Button from "../../common/button/Button";
import { useUserStore } from "../../../store/user";

const Error: FC<any> = ({
  hideModal,
  isModalOpened,
  textOpenError,
  isNeedButton = true,
}) => {
  const setIsPayment = useUserStore((state) => state.setIsPayment);

  const buyGems = () => {
    hideModal && hideModal();
    setIsPayment(true);
  };

  return (
    <ModalWrapper isModalOpened={isModalOpened} hideModal={hideModal}>
      <div className={styles.error}>
        <h5>{textOpenError == 'To confirm you registration, please check your email' ? 'Success' : 'Error'}</h5>

        <p>{textOpenError || "No enough gems. You need at least 50!"}</p>

        {isNeedButton && <Button text={"Buy gems"} onClick={buyGems} />}
      </div>
    </ModalWrapper>
  );
};

export default Error;
