import { useDropzone } from "react-dropzone";
import { useCallback, useEffect, useState } from "react";
import Button from "../common/button/Button";

import { ReactComponent as Cloud } from "./assets/Cloud_Upload.svg";

import styles from "./DragPicture.module.scss";
import RegenerateButton from "./RegenerateButton";
import { useUserStore } from "../../store/user";
import { usePictureStore } from "../../store/picture";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Loading from "../common/loading/Loading";
import Error from "../modals/error/Error";
import { useCheckPicture } from "../../api/pictureApi";

const DragPicture = ({ socket, setSocket }: any) => {
  const picture = usePictureStore((state) => state.picture);
  const setPicture = usePictureStore((state) => state.setPicture);
  const [isTab, setIsTab] = useState(false);
  const isAuth = useUserStore((state) => state.isAuth);
  const user = useUserStore((state) => state.user);
  const minusBalance = useUserStore((state) => state.minusBalance);
  const setIsGenerating = usePictureStore((state) => state.setIsGenerating);
  const setIsDownload = usePictureStore((state) => state.setIsDownload);
  const isGenerating = usePictureStore((state) => state.isGenerating);
  const setIsOpenedModalLogin = useUserStore(
    (state) => state.setIsOpenedModalLogin,
  );
  const { t } = useTranslation();
  const [generatedPicture, setGeneratedPicture] = useState<any>("");
  const bodyType = usePictureStore((state) => state.bodyType);
  const [isOpenError, setIsOpenError] = useState(false);
  const [textOpenError, setTextOpenError] = useState<null | string>(null);

  const { mutate, data } = useCheckPicture();

  useEffect(() => {
    if (localStorage.getItem("taskId") && localStorage.getItem("cacheKey")) {
      mutate({
        task_id: localStorage.getItem("taskId"),
        cache_key: localStorage.getItem("cacheKey"),
      });
    }
  }, []);

  useEffect(() => {
    if (data) {
      if (data.task_status === "SUCCESS") {
        localStorage.removeItem("taskId");
        localStorage.removeItem("cacheKey");
      } else if (
        data.task_status === "PENDING" ||
        data.task_status === "RECEIVED"
      ) {
        setPicture("data:image/png;base64," + data.current_img);
        setIsGenerating(true);
      } else {
        localStorage.removeItem("taskId");
        localStorage.removeItem("cacheKey");
      }
    }
  }, [data]);

  useEffect(() => {
    if (user && user.id && !socket) {
      setSocket(
        new WebSocket(
          `wss://wsocket.nuds.ai/ws/gen_result/${user.id}/?token=${localStorage.getItem("access")}`,
        ),
      );
    }
  }, [user, socket]);

  useEffect(() => {
    if (socket) {
      socket.addEventListener("message", (e: any) => {
        if (JSON.parse(e.data).task_id && JSON.parse(e.data).cache_key) {
          localStorage.setItem("taskId", JSON.parse(e.data).task_id);
          localStorage.setItem("cacheKey", JSON.parse(e.data).cache_key);
        }

        if (JSON.parse(e.data).error) {
          setGeneratedPicture(null);
          setIsGenerating(false);
          setIsDownload(false);

          if (JSON.parse(e.data).error === "bad age") {
            setIsOpenError(true);
            setTextOpenError("Ошибка возраста!");
          }

          localStorage.removeItem("taskId");
          localStorage.removeItem("cacheKey");
        } else if (JSON.parse(e.data).image) {
          const data = JSON.parse(e.data);
          setGeneratedPicture("data:image/png;base64," + data.image);
          setIsGenerating(false);
          setIsDownload(true);
          minusBalance(user);
          localStorage.removeItem("taskId");
          localStorage.removeItem("cacheKey");
        }
      });
    }
  }, [socket]);

  useEffect(() => {
    if (bodyType) {
      setGeneratedPicture(null);
    }
  }, [bodyType]);

  const handleGenerate = () => {
    if (isAuth) {
      if (user?.coins < 50) {
        setIsOpenError(true);
        return;
      }

      const formData = new FormData();
      formData.set("picture", picture!);

      const wsSend = function () {
        if (!socket.readyState) {
          const image = new Image();
          image.onload = () => {
            setTimeout(function () {
              socket.send(
                JSON.stringify({
                  image: picture!.split(",")[1],
                  width: image.width,
                  height: image.height,
                  uid: user.id,
                  gen_type: bodyType ? "redress" : "undress",
                  mask_type: bodyType || null,
                }),
              );
            }, 200);
          };
          image.src = picture!;
        } else {
          const image = new Image();
          image.onload = () => {
            setTimeout(function () {
              socket.send(
                JSON.stringify({
                  image: picture!.split(",")[1],
                  width: image.width,
                  height: image.height,
                  uid: user.id,
                  gen_type: bodyType ? "redress" : "undress",
                  mask_type: bodyType || null,
                }),
              );
            }, 200);
          };
          image.src = picture!;
        }
      };

      wsSend();

      setIsGenerating(true);
    } else {
      setIsOpenedModalLogin(true);
    }
  };

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      try {
        const reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onloadend = () => {
          const base64data = reader.result;
          setPicture(base64data!.toString());
        };
      } catch (error) {
        console.log(error);
      }
    },
    [setPicture],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png", ".jpg", ".jpeg", ".HEIF"],
    },
  });

  const onRegenerate = () => {
    setPicture(null);
    setIsGenerating(false);
    setIsDownload(false);
    setGeneratedPicture(null);
  };

  useEffect(() => {
    const setWindowSize = () => {
      setIsTab(window.matchMedia("(max-width: 640px)").matches);
    };

    setWindowSize();

    window.addEventListener("resize", setWindowSize);

    return () => {
      window.removeEventListener("resize", setWindowSize);
    };
  }, [setIsTab]);

  if (picture) {
    return (
      <>
        <div className={styles.upload}>
          <div className={styles.upload__grid}>
            <div className={styles.upload__preview}>
              <img src={picture.toString()} alt="" />

              <div className={styles.upload__custom}>
                <RegenerateButton
                  onRegenerate={onRegenerate}
                  variant={isTab ? "small" : "big"}
                  disabled={isGenerating}
                />

                <div className={styles.upload__custom_btn}>
                  <Button
                    text={t("undress")}
                    variant={isTab ? "small" : "normal"}
                    onClick={handleGenerate}
                    disabled={isGenerating}
                    disabledColor={"dark"}
                  />
                </div>
              </div>
            </div>

            <div
              className={classNames(
                styles.upload__preview,
                styles.upload__preview_result,
              )}
            >
              {!isGenerating && !generatedPicture && (
                <div>
                  <p>{t("there_will_be")}</p>
                </div>
              )}

              {isGenerating && (
                <div>
                  <p>{t("just_a_little")}</p>
                  <p>{t("this_will_take")}</p>

                  {isGenerating && <Loading />}
                </div>
              )}

              {generatedPicture && (
                <img src={generatedPicture.toString()} alt="" />
              )}
            </div>
          </div>

          <div className={styles.upload__custom_mobile}>
            <RegenerateButton
              onRegenerate={onRegenerate}
              variant={isTab ? "small" : "big"}
              disabled={isGenerating}
            />

            <div className={styles.upload__custom_btn}>
              <Button
                text={t("undress")}
                variant={isTab ? "small" : "normal"}
                onClick={handleGenerate}
                disabled={isGenerating}
                disabledColor={"dark"}
              />
            </div>
          </div>
        </div>

        <Error
          isModalOpened={isOpenError}
          hideModal={() => setIsOpenError(false)}
          textOpenError={textOpenError}
        />
      </>
    );
  }

  return (
    <div className={styles.upload}>
      <div {...getRootProps()}>
        <input {...getInputProps()} />

        <div>
          <Cloud />

          <p>{t("upload_a_picture")}</p>

          <div className={styles.upload__button}>
            <Button text={t("upload_photo")} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DragPicture;
