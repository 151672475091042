import React, { useEffect, useState } from "react";
import { Outlet, useSearch } from "@tanstack/react-router";

import Container from "../common/container/Container";

import { useUserStore } from "../../store/user";

import Compliance from "../modals/compliance/Compliance";
import Check from "../modals/check/Check";
import Password from "../modals/password/Password";
import {
  useFreeCoinUser,
  useGetTeachCount,
  useGetUserInfo,
} from "../../api/userApi";
import { usePictureStore } from "../../store/picture";
import Payment from "../modals/payment/Payment";
import Error from "../modals/error/Error";
import Success from "../modals/success/Success";

const Main = () => {
  const search = useSearch({ strict: false });
  const isOpenedModalLogin = useUserStore((state) => state.isOpenedModalLogin);
  const setIsOpenedModalLogin = useUserStore(
    (state) => state.setIsOpenedModalLogin,
  );
  const setIsAuth = useUserStore((state) => state.setIsAuth);
  const setUser = useUserStore((state) => state.setUser);

  const setIsPayment = useUserStore((state) => state.setIsPayment);
  const isPayment = useUserStore((state) => state.isPayment);

  const setIsCheckingAuth = useUserStore((state) => state.setIsCheckingAuth);
  const { data } = useGetUserInfo();
  const isGenerating = usePictureStore((state) => state.isGenerating);

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenError, setIsOpenError] = useState(false);
  const [textOpenError, setTextOpenError] = useState("");


  const [isOpenSuccess, setIsOpenSuccess] = useState(false);
  const [textOpenSuccess, setTextOpenSuccess] = useState("");


  const [isAgeWarning, setIsAgeWarning] = useState(
    !localStorage.getItem("age"),
  );
  const { mutate } = useFreeCoinUser();

  useEffect(() => {
    const getUser = () => {
      if (localStorage.getItem("access")) {
        setIsCheckingAuth(false);
        setIsAuth(true);
        setUser(data);
      } else {
        setIsCheckingAuth(false);
        setIsAuth(false);
      }
    };

    getUser();
  }, [data, setUser, setIsCheckingAuth, setIsAuth]);

  const handleAcceptWarning = () => {
    mutate({ free_coin_type: "first" });
    localStorage.setItem("age", "true");
    setIsAgeWarning(false);
  };

  useEffect(() => {
    if (localStorage.getItem("access")) {
      setIsAuth(true);
    }
  }, [setIsAuth]);

  useEffect(() => {
    if (
      search["set-password-by-token"] ||
      search["set-password-forgot-by-token"]
    ) {
      setIsOpen(true);
    }
  }, [setIsOpen, search]);

  useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      if (isGenerating) {
        e.preventDefault();
        e.returnValue = "";

        const confirmationMessage = "Are you sure you want to leave the page?";
        e.returnValue = confirmationMessage;

        return confirmationMessage;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isGenerating]);

  return (
    <Container>
      <Outlet />

      <Compliance
        isModalOpened={isAgeWarning}
        handleAcceptWarning={handleAcceptWarning}
        closable={false}
      />

      <Error
        isModalOpened={isOpenError}
        hideModal={() => setIsOpenError(false)}
        textOpenError={textOpenError}
        isNeedButton={false}
      />
      <Success
          isModalOpened={isOpenSuccess}
          hideModal={() => setIsOpenSuccess(false)}
          textOpenError={textOpenSuccess}
          isNeedButton={false}
      />
      <Check
        isModalOpened={isOpenedModalLogin}
        hideModal={() => setIsOpenedModalLogin(false)}
        setTextOpenError={setTextOpenError}
        setIsOpenError={setIsOpenError}

        setTextOpenSuccess={setTextOpenSuccess}
        setIsOpenSuccess={setTextOpenSuccess}
      />

      <Password isModalOpened={isOpen} setIsOpen={setIsOpen} />

      <Payment
        isModalOpened={isPayment}
        hideModal={() => setIsPayment(false)}
      />
    </Container>
  );
};

export default Main;
