import { FC } from "react";
import { InputInterface } from "../../../interface/component/common/inputInterface";
import styles from "./Input.module.scss";

const Input: FC<InputInterface> = ({
  value,
  setValue, 
  type = "text",
  placeholder = "text",
  id,
  name,
  maxLength,
  minLength,
  required,
  disabled,
  onChange,
  style
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (setValue) { 
      setValue(e.target.value);
    }
    if (onChange) {
      onChange(e); 
    } 
  };

  return (
    <div className={styles.input}>
      <input
        id={id}
        type={type}
        value={value}
        onChange={handleChange} 
        placeholder={placeholder}
        name={name}
        maxLength={maxLength}
        minLength={minLength}
        required={required}
        disabled={disabled}
        style={style}
      />
    </div>
  );
};

export default Input;
