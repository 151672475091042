import { createFileRoute } from "@tanstack/react-router";
import Button from "../../../component/common/button/Button";
import Footer from "../../../component/footer/Footer";

import styles from "../../../assets/styles/godzi-success.module.scss";
import { FormEvent } from "react";
import { useNavigate } from "@tanstack/react-router";

function SuccessForm() {
  const navigate = useNavigate();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await navigate({ to: "/" });
  };

  return (
    <div>
      <form className={styles.form__success_payment} onSubmit={handleSubmit}>
        <div className={styles.content}>
          <h2>Payment Successful</h2>
          <p>Thank you for your purchase!</p>
          <Button baseType="submit" text="Back Home" />
        </div>
      </form>
      <Footer />
    </div>
  );
}

// @ts-ignore
export const Route = createFileRoute("/godzi/success")({
  component: SuccessForm,
});

export default SuccessForm;
