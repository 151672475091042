import Button from "../common/button/Button";

import AvatarAndBalance from "../avatarAndBalance/AvatarAndBalance";
import GetFreeCoins from "../getFreeCoins/GetFreeCoins";

import styles from "./SideBar.module.scss";
import SettingDownload from "../settingDownload/SettingDownload";
import { useUserStore } from "../../store/user";
import { usePictureStore } from "../../store/picture";
import SocialMedia from "../socialMedia/socialMedia";
import { useNavigate } from "@tanstack/react-router";
import CustomizedSettings from "../modals/customizedSettings/CustomizedSettings";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const SideBar = ({ profile, socket }: { profile?: boolean; socket?: any }) => {
  const isAuth = useUserStore((state) => state.isAuth);
  const isDownload = usePictureStore((state) => state.isDownload);
  const isGenerating = usePictureStore((state) => state.isGenerating);
  const [isOpen, setIsOpen] = useState(false);
  const setIsPayment = useUserStore((state) => state.setIsPayment);
  const picture = usePictureStore((state) => state.picture);
  const setIsOpenedModalLogin = useUserStore(
    (state) => state.setIsOpenedModalLogin,
  );
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleDownload = () => {
    setIsOpen(true);
  };

  const handleRefill = () => {
    navigate({ to: "/" });
  };

  const handleAddBalance = () => {
    if (isAuth) {
      setIsPayment(true);
    } else {
      setIsOpenedModalLogin(true);
    }
  };

  return (
    <>
      <div className={styles.sidebar}>
        <AvatarAndBalance />

        <SettingDownload />

        <div className={styles.sidebar__download}>
          {!profile && (
            <div>
              <Button
                text={t("try_fetish_styles")}
                disabled={isGenerating || !picture}
                variant={"big"}
                onClick={handleDownload}
              />
            </div>
          )}

          {profile && (
            <Button
              text={t("upload_photo")}
              variant={"big"}
              onClick={handleRefill}
            />
          )}
        </div>

        <div className={styles.sidebar__buy}>
          <Button
            text={t("buy_more_gems")}
            variant={"big"}
            type={"straight"}
            onClick={handleAddBalance}
          />
        </div>

        {/*<GetFreeCoins />*/}

        <SocialMedia />
      </div>

      <CustomizedSettings
        isModalOpened={isOpen}
        hideModal={() => setIsOpen(false)}
        socket={socket}
      />
    </>
  );
};

export default SideBar;
