import { FC } from "react";

import { ButtonInterface } from "../../../interface/component/common/buttonInterface";

import styles from "./Button.module.scss";
import classNames from "classnames";

const Button: FC<ButtonInterface> = ({
  text,
  disabled = false,
  variant = "normal",
  type = "rounded",
  disabledColor = "light",
  onClick,
  color = "gradient",
}) => {
  if (type === "straight") {
    return (
      <button
        className={classNames(styles.button, styles.button__straight, {
          [styles.button__straight_big]: variant === "big",
          [styles.button__straight_normal]: variant === "normal",
        })}
        disabled={disabled}
        onClick={() => {
          onClick && onClick();
        }}
      >
        {text}
      </button>
    );
  }

  return (
    <button
      className={classNames(styles.button, {
        [styles.button_big]: variant === "big",
        [styles.button_normal]: variant === "normal",
        [styles.button_small]: variant === "small",
        [styles.button_extra_small]: variant === "extraSmall",
        [styles.button_disabled]: disabledColor === "dark",
        [styles.button_color_gradient]: color === "gradient",
        [styles.button_color_gray]: color === "gray",
      })}
      disabled={disabled}
      onClick={() => {
        onClick && onClick();
      }}
    >
      {text}
    </button>
  );
};

export default Button;
