import { FC, useState } from "react";
import classNames from "classnames";

import ModalWrapper from "../ModalWrapper";

import Button from "../../common/button/Button";

import { ModalInterface } from "../../../interface/component/modals/modalsInterface";

import styles from "./CustomizedSettings.module.scss";
import { usePictureStore } from "../../../store/picture";
import { useUserStore } from "../../../store/user";
import { useGetDresses } from "../../../api/userApi";

const CustomizedSettings: FC<any> = ({ isModalOpened, hideModal, socket }) => {
  const isAuth = useUserStore((state) => state.isAuth);
  const setIsOpenedModalLogin = useUserStore(
    (state) => state.setIsOpenedModalLogin,
  );
  const { data } = useGetDresses();
  const [localSetting, setLocalSetting] = useState(null);
  const picture = usePictureStore((state) => state.picture);
  const user = useUserStore((state) => state.user);
  const setBodyType = usePictureStore((state) => state.setBodyType);
  const setIsGenerating = usePictureStore((state) => state.setIsGenerating);
  const setIsPayment = useUserStore((state) => state.setIsPayment);
  const handleChoose = () => {
    if (isAuth) {
      if (localSetting) {
        if (user?.coins < 50) {
          hideModal && hideModal();
          setIsPayment(true);
          return;
        }

        if (!socket.readyState) {
          const image = new Image();
          image.onload = () => {
            setTimeout(function () {
              socket.send(
                JSON.stringify({
                  image: picture!.split(",")[1],
                  width: image.width,
                  height: image.height,
                  uid: user.id,
                  gen_type: "redress",
                  mask_type: localSetting || null,
                }),
              );
            }, 200);
          };
          image.src = picture!;
        } else {
          const image = new Image();
          image.onload = () => {
            setTimeout(function () {
              socket.send(
                JSON.stringify({
                  image: picture!.split(",")[1],
                  width: image.width,
                  height: image.height,
                  uid: user.id,
                  gen_type: "redress",
                  mask_type: localSetting || null,
                }),
              );
            }, 200);
          };
          image.src = picture!;
        }
        setIsGenerating(true);
        setBodyType(localSetting);
        hideModal && hideModal();
      }
    } else {
      hideModal && hideModal();
      setIsOpenedModalLogin(true);
    }
  };

  return (
    <ModalWrapper isModalOpened={isModalOpened} hideModal={hideModal}>
      <div className={styles.customize}>
        <h5>Customized your girl</h5>

        <div>
          <div className={styles.customize__item_row}>
            {data?.msg?.map((item: any) => (
              <div
                key={item.title}
                className={classNames(styles.customize__item_card, {
                  [styles.customize__item_card_active]:
                    localSetting === item.title,
                })}
                onClick={() => setLocalSetting(item.title)}
              >
                <img src={item.image} alt="" />

                <p>{item.title}</p>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.customize__button}>
          <Button text={"Generate"} onClick={handleChoose} />
        </div>

        <div className={styles.customize__button_cancel}>
          <button
            onClick={() => {
              hideModal && hideModal();
              setBodyType("");
              setLocalSetting(null);
            }}
          >
            Clean all
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default CustomizedSettings;
