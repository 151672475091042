import { useState } from "react";
import classNames from "classnames";
import { useNavigate } from "@tanstack/react-router";

import LanguageDropdown from "./LanguageDropdown";

import avatar from "../../../assets/avatar.png";
import { ReactComponent as Arrow } from "../assets/Arrow.svg";

import { useUserStore } from "../../../store/user";

import styles from "./Avatar.module.scss";

const Avatar = () => {
  const [language, setLanguage] = useState(localStorage.getItem("lng") || "en");
  const [isOpen, setIsOpen] = useState(false);
  const isAuth = useUserStore((state) => state.isAuth);
  const user = useUserStore((state) => state.user);
  const setIsOpenedModalLogin = useUserStore(
    (state) => state.setIsOpenedModalLogin,
  );
  const navigate = useNavigate();

  const handleProfile = async () => {
    if (isAuth) {
      // setIsOpenedModalLogin(true);
      await navigate({ from: "/", to: "/profile" });
    } else {
      setIsOpenedModalLogin(true);
    }
  };

  const handleChangeLanguage = (lng: string) => {
    // i18n.changeLanguage(lng.toLowerCase());
    localStorage.setItem("lng", lng.toLowerCase());
    setLanguage(lng);
    window.location.reload();
  };

  return (
    <div className={styles.avatar}>
      <img src={user?.avatar || avatar} alt="" onClick={handleProfile} />

      <div className={styles.language__wrapper}>
        <div
          className={classNames(styles.language, {
            [styles.language__open]: isOpen,
          })}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div>{language}</div>

          <Arrow />
        </div>

        {isOpen && (
          <LanguageDropdown
            language={language}
            handleChangeLanguage={handleChangeLanguage}
            setIsOpen={setIsOpen}
          />
        )}
      </div>
    </div>
  );
};

export default Avatar;
