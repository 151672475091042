import React from 'react'

import { createFileRoute } from '@tanstack/react-router'
import { useNavigate } from '@tanstack/react-router'

function SuccessUsdBridgeGtm() {
  const navigate = useNavigate()

  React.useEffect(() => {
    const aeffect = async () => {
      await navigate({ from: '/godzi/success/usd/$gems_qty', to: '/godzi/success/' })
    }
    aeffect()
  }, [])

  return (
    <div>
      <p>Success Bridge GTM</p>
    </div>
  )
}

// @ts-ignore
export const Route = createFileRoute('/godzi/success/usd/$gems_qty')({
  component: SuccessUsdBridgeGtm,
})

export default SuccessUsdBridgeGtm
