import { ReactComponent as Grid } from "./assets/More_Grid_Big.svg";
import { ReactComponent as Try } from "./assets/try.svg";
import { ReactComponent as Coin } from "../../assets/coin.svg";

import styles from "./SettingDownload.module.scss";
import CustomizedSettings from "../modals/customizedSettings/CustomizedSettings";
import React, { useState } from "react";
import { useUserStore } from "../../store/user";
import { usePictureStore } from "../../store/picture";

const SettingDownload = () => {
  const isAuth = useUserStore((state) => state.isAuth);
  const picture = usePictureStore((state) => state.picture);
  const setIsOpenedModalLogin = useUserStore(
    (state) => state.setIsOpenedModalLogin,
  );

  const handleDownload = () => {
    if (isAuth) {
      if (picture) {
        const link = document.createElement("a");
        link.href = picture;
        link.download = "image.jpg";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } else {
      setIsOpenedModalLogin(true);
    }
  };

  return (
    <>
      <div className={styles.setting}>
        {/*<div className={styles.setting__detail}>*/}
        {/*  <button>*/}
        {/*    <Grid />*/}
        {/*  </button>*/}

        {/*  <div className={styles.setting__detail_try}>*/}
        {/*    <Try />*/}
        {/*  </div>*/}
        {/*</div>*/}

        <div className={styles.setting__quality}>
          <div
            onClick={handleDownload}
            className={styles.setting__quality_active}
          >
            <button disabled={true}>SD</button>
          </div>

          <div className={styles.setting__quality_try}>
            <button>
              HD <Coin />
            </button>
          </div>

          <div className={styles.setting__quality_try}>
            <button>
              4HD <Coin />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingDownload;
