import styles from "./Switcher.module.scss";
import classNames from "classnames";
import { FC } from "react";

const Switcher: FC<any> = ({ isSwitch, setIsSwitch }) => {
  return (
    <label className={styles.switch}>
      <input
        type="checkbox"
        checked={isSwitch}
        onChange={(e) => setIsSwitch(!isSwitch)}
      />
      <span className={classNames(styles.slider, styles.round)}></span>
    </label>
  );
};

export default Switcher;
