import ModalWrapper from "../ModalWrapper";

import styles from "./Password.module.scss";
import Input from "../../common/input/Input";
import Button from "../../common/button/Button";
import { FC, useEffect, useState } from "react";
import { ModalInterface } from "../../../interface/component/modals/modalsInterface";
import { useNavigate, useSearch } from "@tanstack/react-router";
import {
  useSetPasswordForgotUser,
  useSetPasswordUser,
} from "../../../api/userApi";
import { useUserStore } from "../../../store/user";

const Password: FC<ModalInterface> = ({ isModalOpened, setIsOpen }) => {
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const { mutate, data, status } = useSetPasswordUser();
  const {
    mutate: mutateForgot,
    data: dataForgot,
    status: statusForgot,
  } = useSetPasswordForgotUser();
  const search = useSearch({ strict: false });
  const navigate = useNavigate();
  const setIsAuth = useUserStore((state) => state.setIsAuth);
  useUserStore((state) => state.setUser);
  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (passwordRepeat.length < 6 && password.length < 6) return;
    if (passwordRepeat !== password) return;

    if (search["set-password-by-token"]) {
      mutate({ password, id: search["set-password-by-token"] });
    } else {
      mutateForgot({ password, id: search["set-password-forgot-by-token"] });
    }
  };

  useEffect(() => {
    if (data && status === "success") {
      localStorage.setItem("access", data.access_token);
      localStorage.setItem("refresh", data.refresh_token);
      setIsAuth(true);

      navigate({ to: "/" });
      setIsOpen && setIsOpen(false);
    } else if (status === "error") {
      // alert("Проверьте введеные данные");
    }
  }, [data, status, setIsOpen, setIsAuth, navigate]);

  useEffect(() => {
    if (dataForgot && statusForgot === "success") {
      localStorage.setItem("access", dataForgot.access_token);
      localStorage.setItem("refresh", dataForgot.refresh_token);
      setIsAuth(true);

      navigate({ to: "/" });
      setIsOpen && setIsOpen(false);
    } else if (status === "error") {
      // alert("Проверьте введеные данные");
    }
  }, [dataForgot, statusForgot, setIsAuth, setIsOpen, navigate, status]);

  return (
    <ModalWrapper isModalOpened={isModalOpened} closable={false} close={false}>
      <div className={styles.password}>
        <h5>Password</h5>

        <form onSubmit={handleSubmit}>
          <div className={styles.password__password}>
            <Input
              value={password}
              placeholder={"Password"}
              setValue={setPassword}
            />
          </div>

          <div className={styles.password__password}>
            <Input
              value={passwordRepeat}
              placeholder={"Repeat password"}
              setValue={setPasswordRepeat}
            />
          </div>

          <div className={styles.password__button}>
            <Button text={"Save"} />
          </div>
        </form>
      </div>
    </ModalWrapper>
  );
};

export default Password;
