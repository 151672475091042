import React, { FC, MouseEvent } from "react";
import classNames from "classnames";

import { ReactComponent as Close } from "./assets/Close_MD.svg";

import { ModalWrapperTypes } from "../../interface/component/modals/modalsInterface";

import styles from "./ModalWrapper.module.scss";

const ModalWrapper: FC<ModalWrapperTypes> = ({
  children,
  hideModal,
  isModalOpened,
  close = true,
}) => {
  const hideModalPanel = () => {
    if (hideModal) {
      hideModal();
    }
  };

  const stop = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <div
      onMouseDown={hideModalPanel}
      className={classNames(styles.modal, {
        [styles.visible]: isModalOpened,
      })}
    >
      <div
        onMouseDown={stop}
        className={classNames(styles.modal__wrapper, {
          [styles.visible]: isModalOpened,
        })}
      >
        {close && (
          <button className={styles.btn_close} onClick={hideModal}>
            <Close />
          </button>
        )}
        {children}
      </div>
    </div>
  );
};

export default ModalWrapper;
