import { useEffect } from "react";
import classNames from "classnames";

import { LanguageDropdownInterface } from "../../../interface/component/LanguageDropdownInterface";

import styles from "./Avatar.module.scss";

const LanguageDropdown = ({
  language,
  handleChangeLanguage,
  setIsOpen,
}: LanguageDropdownInterface) => {
  useEffect(() => {
    const closeDropdown = (e: any) => {
      if (!e.target.closest("." + styles.language__wrapper)) {
        setIsOpen(false);
      }
    };

    window.addEventListener("click", closeDropdown);

    return () => {
      window.removeEventListener("click", closeDropdown);
    };
  }, [setIsOpen]);

  return (
    <div className={styles.language__dropdown}>
      <p
        className={classNames({
          [styles.language__dropdown_active]: language === "En",
        })}
        onClick={() => handleChangeLanguage("En")}
      >
        English
      </p>
      <p
        className={classNames({
          [styles.language__dropdown_active]: language === "Ru",
        })}
        onClick={() => handleChangeLanguage("Sp")}
      >
        Spain
      </p>
      <p
        className={classNames({
          [styles.language__dropdown_active]: language === "Ru",
        })}
        onClick={() => handleChangeLanguage("Pt")}
      >
        Portugal
      </p>
      <p
        className={classNames({
          [styles.language__dropdown_active]: language === "Ru",
        })}
        onClick={() => handleChangeLanguage("Ar")}
      >
        Arabic
      </p>
      <p
        className={classNames({
          [styles.language__dropdown_active]: language === "Ru",
        })}
        onClick={() => handleChangeLanguage("Pl")}
      >
        Polish
      </p>
      <p
        className={classNames({
          [styles.language__dropdown_active]: language === "Ru",
        })}
        onClick={() => handleChangeLanguage("Bg")}
      >
        Bulgarian
      </p>
      <p
        className={classNames({
          [styles.language__dropdown_active]: language === "Ru",
        })}
        onClick={() => handleChangeLanguage("Hu")}
      >
        Hungarian
      </p>
      <p
        className={classNames({
          [styles.language__dropdown_active]: language === "Ru",
        })}
        onClick={() => handleChangeLanguage("Ro")}
      >
        Romanian
      </p>
      <p
        className={classNames({
          [styles.language__dropdown_active]: language === "Ru",
        })}
        onClick={() => handleChangeLanguage("Da")}
      >
        Danish
      </p>
      <p
        className={classNames({
          [styles.language__dropdown_active]: language === "Ru",
        })}
        onClick={() => handleChangeLanguage("Fi")}
      >
        Finnish
      </p>
      <p
        className={classNames({
          [styles.language__dropdown_active]: language === "Ru",
        })}
        onClick={() => handleChangeLanguage("It")}
      >
        Italian
      </p>
      <p
        className={classNames({
          [styles.language__dropdown_active]: language === "Ru",
        })}
        onClick={() => handleChangeLanguage("Fr")}
      >
        French
      </p>
      <p
        className={classNames({
          [styles.language__dropdown_active]: language === "Ru",
        })}
        onClick={() => handleChangeLanguage("Zh")}
      >
        Chinese
      </p>
      <p
        className={classNames({
          [styles.language__dropdown_active]: language === "Ru",
        })}
        onClick={() => handleChangeLanguage("Ko")}
      >
        Korean
      </p>
      <p
        className={classNames({
          [styles.language__dropdown_active]: language === "Ru",
        })}
        onClick={() => handleChangeLanguage("Hi")}
      >
        Hindi
      </p>
      <p
        className={classNames({
          [styles.language__dropdown_active]: language === "Ru",
        })}
        onClick={() => handleChangeLanguage("Cs")}
      >
        Czech
      </p>
      <p
        className={classNames({
          [styles.language__dropdown_active]: language === "Ru",
        })}
        onClick={() => handleChangeLanguage("De")}
      >
        German
      </p>
      <p
        className={classNames({
          [styles.language__dropdown_active]: language === "Ru",
        })}
        onClick={() => handleChangeLanguage("Sv")}
      >
        Swedish
      </p>
      <p
        className={classNames({
          [styles.language__dropdown_active]: language === "Ru",
        })}
        onClick={() => handleChangeLanguage("No")}
      >
        Norwegian
      </p>
      <p
        className={classNames({
          [styles.language__dropdown_active]: language === "Ru",
        })}
        onClick={() => handleChangeLanguage("Ja")}
      >
        Japanese
      </p>
    </div>
  );
};

export default LanguageDropdown;
