import { create } from "zustand";

import { UserState } from "../interface/zustand/user";

export const useUserStore = create<UserState>((set) => ({
  isOpenedModalLoginPassword: false,
  isOpenedModalLogin: false,
  isCheckingAuth: true,
  isAuth: false,
  isPayment: false,
  user: null,
  email: "",
  changeEmail: (email) => set(() => ({ email })),
  setIsAuth: (isAuth) => set(() => ({ isAuth })),
  setIsPayment: (isPayment) => set(() => ({ isPayment })),
  setIsOpenedModalLogin: (isOpenedModalLogin) =>
    set(() => ({ isOpenedModalLogin })),
  setIsOpenedModalLoginPassword: (isOpenedModalLoginPassword) =>
    set(() => ({ isOpenedModalLoginPassword })),
  setUser: (user) => set(() => ({ user })),
  setIsCheckingAuth: (isCheckingAuth) => set(() => ({ isCheckingAuth })),
  minusBalance: (user) =>
    set(() => {
      if (user.coins) {
        user.coins -= 50;
      }

      return { user };
    }),
}));
