import { createFileRoute } from "@tanstack/react-router";
import Button from "../../../component/common/button/Button";
import Footer from "../../../component/footer/Footer";

import styles from "../../../assets/styles/godzi-fail.module.scss";
import { FormEvent } from 'react';
import { useNavigate } from "@tanstack/react-router";

function FailForm() {
  const navigate = useNavigate();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await navigate({ to: "/" });
  };

  return (
    <div>
      <form className={styles.form__fail_payment} onSubmit={handleSubmit}>
        <div className={styles.content}>
          <h2>Payment Failed</h2>
          <p>There was an issue with your payment.</p>
          <Button baseType="submit" text="Back Home"/>
        </div>
      </form>
      <Footer />
    </div>
  );
}

// @ts-ignore
export const Route = createFileRoute("/godzi/fail")({
  component: FailForm,
});

export default FailForm;