import { createFileRoute } from "@tanstack/react-router";
import Footer from "../../component/footer/Footer";
import styles from "../../assets/styles/privacy.module.scss";

// @ts-ignore
export const Route = createFileRoute("/usagePolicy/")({
  component: usagePolicy,
});

function usagePolicy() {
  return (
    <div>
      <div className={styles.usage}>
        <h1>Usage Policy</h1>

        <p>Last updated: July 2024</p>

        <h2>Introduction</h2>

        <p>
          This Usage Policy outlines the acceptable use of the services provided
          by nuds.ai (hereinafter referred to as "the Company," "We," "Us," or
          "Our"). By using our services, you agree to comply with this policy.
          Any violation of this policy may result in suspension or termination
          of your access to our services.
        </p>

        <h2>Acceptable Use</h2>

        <h3>General Use</h3>

        <ul>
          <li>
            Personal Use: Our services are intended for personal use only unless
            otherwise specified by a separate agreement.
          </li>
          <li>
            Age Requirement: You must be 18 years or older to use our services.
          </li>
        </ul>

        <h3>Content Guidelines</h3>

        <ul>
          <li>
            Legal Compliance: Do not upload, share, or distribute any content
            that is illegal under applicable laws, including but not limited to
            child pornography, non-consensual pornography, copyrighted material
            without proper authorization, or any content that promotes illegal
            activities.
          </li>
          <li>
            Respect for Others: Do not upload or distribute content that is
            threatening, harassing, defamatory, hateful, or encourages violence
            or discrimination against individuals or groups based on race,
            ethnicity, national origin, religion, gender, gender identity,
            sexual orientation, disability, or any other characteristic.
          </li>
          <li>
            Accuracy and Authenticity: Ensure that the content you upload is
            accurate and authentic. Do not impersonate others or misrepresent
            your identity.
          </li>
        </ul>

        <h3>Prohibited Actions</h3>

        <ul>
          <li>
            Unauthorized Access: Do not attempt to gain unauthorized access to
            our systems or any account other than your own.
          </li>
          <li>
            Harmful Activities: Do not upload viruses, malware, or any other
            harmful code. Do not engage in activities that disrupt or interfere
            with the normal operation of our services.
          </li>
          <li>
            Spam and Abuse: Do not use our services to send spam or unsolicited
            messages. Do not use our services to abuse, exploit, or take
            advantage of others.
          </li>
        </ul>

        <h3>Intellectual Property</h3>

        <ul>
          <li>
            Respect for Intellectual Property: Respect the intellectual property
            rights of others. Do not upload, share, or distribute content that
            you do not have the right to use.
          </li>
          <li>
            Attribution: When sharing content created using our services,
            provide proper attribution to nuds.ai as required by our licensing
            terms
          </li>
        </ul>

        <h2>Monitoring and Enforcement</h2>

        <ul>
          <li>
            Monitoring: We reserve the right to monitor your use of our services
            to ensure compliance with this Usage Policy. However, we do not
            guarantee that we will monitor all activities and are not
            responsible for any user-generated content.
          </li>
          <li>
            Enforcement: We may take any action we deem necessary to enforce
            this Usage Policy, including but not limited to suspending or
            terminating your access to our services, removing content, and
            reporting violations to law enforcement authorities.
          </li>
        </ul>

        <h2>Reporting Violations</h2>

        <p>
          If you become aware of any violation of this Usage Policy, please
          report it to us immediately at: support@nuds.ai.
        </p>

        <h2>Changes to this Usage Policy</h2>

        <p>
          We may update this Usage Policy from time to time. We will notify you
          of any changes by posting the new Usage Policy on this page. Changes
          to this Usage Policy are effective when they are posted on this page.
          Your continued use of our services after any changes indicates your
          acceptance of the new terms.
        </p>

        <h2>Contact Information</h2>

        <p>
          If you have any questions about this Usage Policy, please contact us
          at: support@nuds.ai.
        </p>

        <p>
          By using our services, you agree to this Usage Policy and acknowledge
          that you are responsible for any violations of this policy. Thank you
          for helping us maintain a safe and respectful environment for all
          users.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default usagePolicy;
