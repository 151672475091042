import ModalWrapper from "../ModalWrapper";
import { FC, useState } from "react";

import Button from "../../common/button/Button";

import beforeAfter from "./assets/main console 7.png";
import { ReactComponent as Birthday } from "./assets/Birthday.svg";
import dressing from "./assets/main console 7-1.png";

import { ModalInterface } from "../../../interface/component/modals/modalsInterface";

import styles from "./Compliance.module.scss";

const Compliance: FC<ModalInterface & { handleAcceptWarning: Function }> = ({
  isModalOpened,
  handleAcceptWarning,
  closable,
}) => {
  const closeWindow = () => {
    window.close();
  };
  const [step, setStep] = useState(1);

  return (
    <ModalWrapper
      isModalOpened={isModalOpened}
      closable={closable}
      close={false}
    >
      <div className={styles.onboarding}>
        {step === 1 && (
          <div className={styles.compliance}>
            <div>
              <h5>Compliance</h5>

              <p>
                You must be 18y.o. and above to use our website. <br />
                If you are under 18, unfortunately you may not use it. <br />
                (Come back later when you hit 18y.o.)
              </p>

              <p>
                We do not store any data. <br />
                We do not accept any liability for the images created using this
                site or for their subsequent use. <br />
                The processing of images of minors is strictly prohibited.{" "}
                <br />
                Attempts to do so will result in immediate account blocking.
              </p>
            </div>

            <div>
              <div className={styles.compliance__button}>
                <Button
                  text={"My age is 18+"}
                  onClick={() => {
                    // handleAcceptWarning();
                    setStep((prev) => prev + 1);
                  }}
                />
              </div>

              <div className={styles.compliance__get_out}>
                <button onClick={closeWindow}>Get out</button>
              </div>
            </div>
          </div>
        )}

        {step === 2 && (
          <div className={styles.compliance}>
            <div>
              <h5>How does the service work?</h5>

              <div className={styles.center}>
                <p>
                  Upload the photo you want to undress and enjoy our welcome
                  bonus
                </p>

                <img src={beforeAfter} alt="" />
              </div>
            </div>

            <div>
              <div className={styles.compliance__button}>
                <Button
                  text={"Next"}
                  onClick={() => {
                    // handleAcceptWarning();
                    setStep((prev) => prev + 1);
                  }}
                />
              </div>

              <div className={styles.compliance__get_out}>
                <button
                  onClick={() => {
                    // handleAcceptWarning();
                    setStep((prev) => prev - 1);
                  }}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        )}
        {step === 3 && (
          <div className={styles.compliance}>
            <div>
              <h5>Dressing up option</h5>

              <div className={styles.center}>
                <p>Live out your wildest fantasies, be bold in your desires</p>

                <img src={dressing} alt="" />
              </div>
            </div>

            <div>
              <div className={styles.compliance__button}>
                <Button
                  text={"Next"}
                  onClick={() => {
                    // handleAcceptWarning();
                    setStep((prev) => prev + 1);
                  }}
                />
              </div>

              <div className={styles.compliance__get_out}>
                <button
                  onClick={() => {
                    // handleAcceptWarning();
                    setStep((prev) => prev - 1);
                  }}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        )}

        {step === 4 && (
          <div className={styles.compliance}>
            <div className={styles.vert_center}>
              <Birthday />

              <p>
                Great news! <br /> We're giving away{" "}
                <span>100 Gems just for you</span>.
              </p>
            </div>

            <div>
              <div className={styles.compliance__button}>
                <Button text={"Get 100 gems"} onClick={handleAcceptWarning} />
              </div>

              <div className={styles.compliance__get_out}>
                <button onClick={() => setStep(1)}>Go through again</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </ModalWrapper>
  );
};

export default Compliance;
